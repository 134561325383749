import React, { useState } from "react";
import { Alert } from "./Alert";

/**
 * provide a function named "alert" as the only property of our context
 */
export type AlertContextProps = {
    alert: Function;
};

/**
 * by default, the alert function is an empty function when creating the AlertContext
 */
export const AlertContext = React.createContext<AlertContextProps>({
    alert: () => {},
});

/**
 * handle children as props
 */
export type AlertContextProviderProps = {
    children?: React.ReactNode;
};

/**
 * Actual AlertContext provider implements the logic for opening and closing the new Alert.tsx
 */
export const AlertContextProvider: React.FC<AlertContextProviderProps> = ({
    children,
}) => {
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
    const alert = (message: string = "alert") => {
        setAlertMessage(message);
        setAlertIsOpen(true);
    };

    return (
        <AlertContext.Provider value={{alert}}>
            {children}
            <Alert 
                isOpen={alertIsOpen}
                message={alertMessage}
                onClose={() => setAlertIsOpen(false)}
            />
        </AlertContext.Provider>
    );
};