export const msalConfig = {
    auth: {
      clientId: "32e1888e-4939-4732-a034-dc3c51250a3a", // https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/quickStartType//sourceType/Microsoft_AAD_IAM/appId/72dbc588-ec98-4b8d-8d86-9c499a6adc07/objectId/adab167b-3b0a-4ed4-bf35-871307809e43/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADMyOrg/servicePrincipalCreated/true
      authority: "https://login.microsoftonline.com/1dce4791-8eb8-4556-9e49-ce4975223730", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://hrol.iss-digital.com",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };

  export const logoutRequest = {
    postLogoutRedirectUri: "https://hrol.iss-digital.com"
  }

  export const getAccessToken = async (msal) => {
    // if the user is already logged in you can acquire a token
    if (msal.accounts[0]) {
      var tokenRequest = { scopes: ["user.read", "mail.send"], account: msal.accounts[0] };
      return await msal.instance.acquireTokenSilent(tokenRequest).catch(async err => { 
        if(err.name === "InteractionRequiredAuthError"){
          return await msal.instance.acquireTokenPopup(tokenRequest).catch(async err => { console.log('The following error has been caught:\n' + err); });
        } else { // logout the user
          msal.instance.logoutRedirect(logoutRequest).catch(e => console.error(e))
        } 
      });
    } else {
      alert('WARNING: You may not acquire an access token unless you are authenticated. Please log in using your ISS credentials!') // user is not logged in, you will need to log them in to acquire a token
    }
  }