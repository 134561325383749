import { Navbar, Nav, NavDropdown} from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import logo from "../images/hurricane.png";
import { AuthButton } from "./AuthButton";
import { useMsal } from "@azure/msal-react";

function HRNav() {

  const { accounts } = useMsal();
  return (
    <>
        <Navbar id='navbar' bg="dark" fixed="top" variant="dark" expand="lg" collapseOnSelect>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Item className='brand-name'>
                  <Navbar.Brand className="navbar-logo"><NavLink to="/"><img alt="" src={logo} width="30" id="logo" /></NavLink></Navbar.Brand>
                  <Navbar.Text>HR Offer Letter</Navbar.Text>
              </Nav.Item>
              <Nav.Item>
                {accounts.length !== 0 && ( accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || accounts[0]?.idTokenClaims?.roles.includes("hr_practitioner") || accounts[0]?.idTokenClaims?.roles.includes("hiring_manager")) ?
                <NavDropdown id="request-dropdown" title="Request Forms" menuVariant="dark">
                    <NavDropdown.Item><NavLink className="dropdownLink" to="/offer-letter-request">Offer Letter Request Form</NavLink></NavDropdown.Item>
                </NavDropdown>:<></>}
              </Nav.Item>
              <Nav.Item>
                {accounts.length !== 0 && ( accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || accounts[0]?.idTokenClaims?.roles.includes("hr_practitioner") || accounts[0]?.idTokenClaims?.roles.includes("hiring_manager"))  ?
                <NavDropdown id="lookup-dropdown" title="Request Actions" menuVariant="dark">
                    {accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || accounts[0]?.idTokenClaims?.roles.includes("hr_practitioner") ? <NavDropdown.Item><NavLink className="dropdownLink" to="/practitioners-lookup">Lookup and Approve Requests</NavLink></NavDropdown.Item>: <></>}
                    {accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || accounts[0]?.idTokenClaims?.roles.includes("hiring_manager") ? <NavDropdown.Item><NavLink className="dropdownLink" to="/manager-lookup">Lookup and Verify Requests</NavLink></NavDropdown.Item>: <></>}
                </NavDropdown>:<></>}
              </Nav.Item>
            </Nav>
            <Nav className="right-nav">
              {accounts.length !== 0 ? <Nav.Item className="signedIn"><Navbar.Text> Signed in as: <span id="username">{accounts[0].name ? accounts[0].name : "UNKNOWN"}</span> </Navbar.Text></Nav.Item> : <></>}
              <Nav.Item><AuthButton /></Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    </>
  );
}

export default HRNav;