import React, { useContext, useState } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import '../styles/OfferLetterRequestForm.css';
import Button from 'react-bootstrap/Button';
import { loginRequest } from "../authConfig";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import Modal from 'react-bootstrap/Modal';
import { Alert, AlertTitle } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../AlertContext';

function ManagerLookup() {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState();
    const [show, setShow] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const [request, setRequest] = useState();
    const [requests, setRequests] = useState([]);
    const [rejection, setRejection] = useState('');
    const [pdf, setPdf] = useState();
    const [userFeedback, setUserFeedback] = useState('');
    const isAuthenticated = useIsAuthenticated();
    const [control, setControl] = useState(true);
    const { alert } = useContext(AlertContext)
    
    // These are the links that will be replaced upon deployment via the pipeline - localhost will become func link
    // const api_url = 'http://localhost:7071/api/hrol/';
    // const api_url = 'https://func-hrol-dev-api.azurewebsites.net/api/hrol/';
    const api_url = 'https://func-hrol-api-prod.azurewebsites.net/api/hrol/';

    // const generator_url = 'http://localhost:7071/api/generator/hrol/';
    // const generator_url = 'https://func-hrol-dev.azurewebsites.net/api/generator/hrol/'
    const generator_url = 'https://func-iss-report-generator-prod.azurewebsites.net/api/generator/hrol/'


    
    React.useEffect(() => {
        if(isAuthenticated) { 
            // RequestAccessToken();
            fetchAllManagerRequests();
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    /**
     * Function that creates a new MSAL request object with a blank redirect, which is then used with acquireTokenPopup to try to automatically retrieve a new access token for the user
     * @param {string} functionName the name of the function (& any parameters) as a string that will be parsed by eval() for execution
     */
    function requiredReauthentication(functionName) {
        const request = {
            ...loginRequest,
            account: accounts[0],
            redirectUri: "https://hrol.iss-digital.com/blank.html" // based on this GitHub issue, in order for a acquireTokenPopup to work, we need to redirect to a blank page instead of our homepage. https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4573#issuecomment-1071143506
        };

        console.log('Reauthentication Required')
        instance.acquireTokenPopup(request).then((response) => {
            setToken(response);
            setControl(true)
            // eslint-disable-next-line
            eval(functionName)
        });
    }

    function fetchAllManagerRequests() {
        const tokenRequest = {
            ...loginRequest,
            account: accounts[0]
        };

        if(control) {
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(response);

                fetch((api_url + 'requests'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                .then((req_ret) => {
                    if(req_ret.status === 401 && req_ret.message.includes('Reauthentication Required')) {
                        throw new Error('Reauthentication Required')
                    }
                    let managersRequests = req_ret.filter(res=>res.requestor_name === accounts[0].idTokenClaims.name)
                    var selectedRequest = req_ret.filter((selected) => selected.id === currentRow);
                    setRequests(managersRequests)
                    setRequest(selectedRequest[0])
                })
                .catch((error) => {
                    setControl(false)
                    if(error.message.includes('Reauthentication Required')) {
                        requiredReauthentication('fetchAllManagerRequests()')
                    } else {
                        alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                        setControl(true)
                    }
                });
            }).catch((e) => {
                alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
            });
        }
    }

    function handleRequestSelection(e) {
        if(typeof e === 'string') {
            e = JSON.parse(e)
        }

        setPdf(undefined)
        setUserFeedback("")
        setRequest(e.row)
        setCurrentRow(e.row.id)
        if (e.row?.blob_id !== '') {
            if(control) {
                fetch((generator_url + 'fetch/' + e.row.blob_id), {headers: {Authorization: token.idToken}, method: 'GET'}).then((response) => {
                    if(response.status === 200) {
                        return response.blob()
                    } else if(response.status === 401) {
                        return response.json()
                    } else {
                        return null
                    }
                })
                .then((blob) => {
                    if(blob?.status === 401 && blob?.message.includes('Reauthentication Required')) {
                        throw new Error('Reauthentication Required')
                    } else {
                        if(blob !== null && blob !== undefined) {
                            let url = URL.createObjectURL(blob);
                            setPdf(<object width="100%" style={{height : "85vh"}} type="application/pdf" data={url}>{''}</object>)
                        }
                    }
                })
                .catch((error) => {
                    setControl(false)
                    if(error.message.includes('Reauthentication Required')) {
                        requiredReauthentication('handleRequestSelection(' + JSON.stringify(e) + ')')
                    } else {
                        alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                        setControl(true)
                    }
                });
            }

        } else {
            setUserFeedback(
            <Alert severity='info'>
                Sorry, your letter has not been generated yet!
            </Alert>)
        }
    };

   function handleVerify() {
        var update_params = {...request}
        update_params['verification_status'] = 'verified'
        update_params['verification_date'] = new Date().toISOString();
        update_params['update_action'] = 'verify'

        if(control) {
            fetch((api_url + 'requests/' + update_params.id), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(update_params)}).then((response) => response.json())
            .then((json) => {
                if(json?.message.includes('Success') === false) {
                    if(json.status === 401 && json.message.includes('Reauthentication Required')) {
                        throw new Error('Reauthentication Required')
                    }
                } else {
                    alert(String(json.message))
                    fetchAllManagerRequests()
                }
            })
            .catch((error) => {
                setControl(false)
                if(error.message.includes('Reauthentication Required')) {
                    requiredReauthentication('handleVerify()')
                } else {
                    alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                    setControl(true)
                }
            });
            setRequest(undefined)
        }
    };

    const handleReject = e => {
        setShow(true)
    };

    function finishRejection() {
        var update_params = {...request}
        update_params['verification_status'] = 'rejected';
        update_params['rejection_reason'] = rejection;
        update_params['update_action'] = 'verification_reject'

        fetch((api_url + 'requests/' + update_params.id), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(update_params)}).then((response) => {
            if(response.status === 200) {
                fetchAllManagerRequests()
                return 'OK'
            } else {
                return response.json()
            }
        }).then((json) => {
            if(json !== 'OK') {
                if(json.status === 401 && json.message.includes('Reauthentication Required')) {
                    throw new Error('Reauthentication Required')
                }
            }
        }).catch((error) => {
            setControl(false)
            if(error.message.includes('Reauthentication Required')) {
                requiredReauthentication('finishRejection()')
            } else {
                alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                setControl(true)
            }
        });
        setRejection('');
        setShow(false);
        setRequest(undefined);
    }

    const navigate = useNavigate()

    const editRequest = (cellValues) => {
        window.sessionStorage.setItem('results', JSON.stringify(cellValues.row))
        navigate('/offer-letter-request', {state: {redirect: true}})
    }

    const columns = [
        { field: 'request_date', headerName: 'Request Date', width: 175 },
        { field: 'requestor_name', headerName: 'Requestor', width: 150 },
        { field: 'date_of_hire', headerName: 'Date of Hire', width: 150 },
        { field: 'position', headerName: 'Position', width: 200 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'approval_status', headerName: 'HR Approval', width: 150 },
        { field: 'verification_status', headerName: 'Manager Approval', width: 150 },
        { field: 'envelope_status', headerName: 'Signature Status', width: 150 },
        { field: 'edit_request', headerName: 'Edit Request', width: 135,
            renderCell: (cellValues) => {
            return (
                <Button
                    style={{ border: '1px solid black' }}
                    variant="primary"
                    // disabled={cellValues.row.envelope_status === 'Completed'}
                    onClick={() => editRequest(cellValues)}
                >
                    Edit Request
                </Button>
            );
        } }
    ];

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size='lg'
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Letter Rejection Reasoning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField placeholder='Enter a rejection reason' fullWidth minRows={4} maxRows={24} variant='filled' multiline id="rejection" name="rejection" value={rejection} onChange={(e) => {const value = e.target.value; setRejection(value)}}></TextField>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShow(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => finishRejection()}>Reject</Button>
                </Modal.Footer>
            </Modal>

            <br /><br /><br />
            <div className="page-container">
                <h2 className="page-title">Request Lookup and Letter Verification:</h2><br />
                <div style={{ height: 400, width: '100%', backgroundColor: 'white', borderRadius: '10px', border: '1px solid black' }}>
                    <DataGrid 
                        rows={requests}
                        columns={columns}
                        disableMultipleSelection={true}
                        onCellClick={(e) => handleRequestSelection(e)}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'request_date', sort: 'desc' }],
                            },
                        }}
                    />
                </div>
                <br />
                    <Button className="verification-buttons" variant='success' onClick={() => handleVerify()} disabled={request === undefined || (request.verification_status !== 'unverified' && request.verification_status !== 'rejected') || request.approval_status === 'rejected' || request.approval_status === 'unapproved' || request.blob_id === '' || request.envelope_status !== 'Unsent'}>{false ? <CircularProgress size={24}/>: 'Verify'}</Button>
                    <Button className="verification-buttons" variant='danger' onClick={handleReject} disabled={request === undefined|| (request.verification_status !== 'unverified' && request.verification_status !== 'verified') || request.approval_status === 'rejected' || request.approval_status === 'unapproved' || request.blob_id === '' || request.envelope_status !== 'Unsent'}>{false ? <CircularProgress size={24}/>: 'Reject'}</Button>
                    {pdf ? <br /> : <div className="user-feedback">{userFeedback}</div> }
                <br />
                {request?.approval_status === 'rejected' ?
                    <div id='reject-reason'>
                        <Alert variant='filled' severity='error'>
                            <AlertTitle>Rejected</AlertTitle>
                            This offer letter request has been REJECTED for the following reason: <br/>
                            {request.rejection_reason}
                        </Alert>
                    </div>
                :
                    <></>
                }
                {pdf && request?.approval_status !== 'rejected' ? <div id="pdfView" className="pdfView">{pdf}</div> : <></>}

            </div>
        </>
    );
}

export default ManagerLookup;