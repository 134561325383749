import React, { useState, useContext } from 'react';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import '../styles/OfferLetterRequestForm.css';
import Button from 'react-bootstrap/Button';
import { loginRequest } from "../authConfig";
import CircularProgress from '@material-ui/core/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import iss_logo from '../images/logo_iss_fullcolor.svg';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import TextField from '@mui/material/TextField';
import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Divider, Switch } from '@material-ui/core';
import { FormControlLabel, FormGroup } from '@mui/material';
import { AlertContext } from '../AlertContext';

function PractitionersLookup() {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState();
    const [currentRow, setCurrentRow] = useState();
    const [request, setRequest] = useState();
    const [requests, setRequests] = useState([]);
    const [showReject, setShowReject] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalControlState, setModalControlState] = useState({ showSignOnBonus: false, showRelocation: false, showRestrictiveCovenants: false, showCustomReturnDate: false, showAdditionalComments: false })
    const [modalInputState, setModalInputState] = useState({ showIncentiveCompensationInput: false, showRestrictiveCovenantsInput: false, showCustomReturnDateInput: false })
    const restrictiveCovenantsTextDefault = 'You agree that, for a period commencing with the commencement of your employment with ISS and continuing for twelve months following the date of termination of your employment with ISS, you shall not (i) engage in Competitive Activities; (ii) have any interest (including as an employee, employer, equity holder, consultant, lender or otherwise) in any entity which engages in direct Competitive Activities with this particular ISS affiliate; or (iii) make any disparaging remarks or statements about ISS or its affiliates to any third parties (including, without limitation, any such remarks or statements to or about any customer, employee, salesperson, distributor, supplier, vendor, manufacturer, representatives, agent, jobber or other person transacting business with ISS).  For purposes hereof, "Competitive Activities" means:  (A) the manufacturing, sale, distribution or marketing of products and/or services, in any location where this particular ISS affiliate operates, that compete with any of the products and/or services provided by this particular ISS affiliate during your employment with ISS; (B) the solicitation, directly or indirectly, of any customer of ISS which was a customer or potential customer during your employment with ISS; and/or (C) the solicitation, directly or indirectly, or hiring of any individual who was employed by ISS during your employment with ISS. This requirement does not supersede any legal restrictions.'
    const [state, setState] = useState({ custom_return_date: '', signOnBonus: '', sobDate: '', relocation: '', showIncentiveCompensation: false, additionalComments: '', restrictiveCovenantsText: restrictiveCovenantsTextDefault })
    const [template, setTemplate] = useState(null);
    const [generating, setGenerating] = useState(null);
    const [rejection, setRejection] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const isAuthenticated = useIsAuthenticated();
    const { alert } = useContext(AlertContext)
    const [control, setControl] = useState(true);

    // These are the links that will be replaced upon deployment via the pipeline - localhost will become func link
    // const api_url = 'http://localhost:7071/api/hrol/';
    // const api_url = 'https://func-hrol-dev-api.azurewebsites.net/api/hrol/';
    const api_url = 'https://func-hrol-api-prod.azurewebsites.net/api/hrol/';

    // const generator_url = 'http://localhost:7071/api/generator/hrol/';
    // const generator_url = 'https://func-hrol-dev.azurewebsites.net/api/generator/hrol/'
    const generator_url = 'https://func-iss-report-generator-prod.azurewebsites.net/api/generator/hrol/'

    React.useEffect(() => {
        if(isAuthenticated) {
            // RequestAccessToken();
            fetchAllRequests();
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    /**
     * Function that creates a new MSAL request object with a blank redirect, which is then used with acquireTokenPopup to try to automatically retrieve a new access token for the user
     * @param {string} functionName the name of the function (& any parameters) as a string that will be parsed by eval() for execution
     */
    function requiredReauthentication(functionName) {
        const request = {
            ...loginRequest,
            account: accounts[0],
            redirectUri: "https://hrol.iss-digital.com/blank.html" // based on this GitHub issue, in order for a acquireTokenPopup to work, we need to redirect to a blank page instead of our homepage. https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4573#issuecomment-1071143506
        };

        console.log('Reauthentication Required')
        instance.acquireTokenPopup(request).then((response) => {
            setToken(response);
            setControl(true)
            // eslint-disable-next-line
            eval(functionName)
        });
    }

    function fetchAllRequests() {
        const tokenRequest = {
            ...loginRequest,
            account: accounts[0]
        };

        if(control) {
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(tokenRequest).then((response) => {
                setToken(response);

                fetch((api_url + 'requests'), {headers: {Authorization: response.idToken}, method: 'GET'}).then((response) => response.json())
                .then((json) => {
                    if(json.status === 401 && json.message.includes('Reauthentication Required')){
                        throw new Error('Reauthentication Required')
                    }
                    var selectedRequest = json.filter((selected) => selected.id === currentRow);
                    setRequests(json)
                    setRequest(selectedRequest[0])
                })
                .catch((error) => {
                    setControl(false)
                    if(error.message.includes('Reauthentication Required')) {
                        requiredReauthentication('fetchAllRequests()')
                    } else {
                        alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                        setControl(true)
                    }
                });
            }).catch((e) => {
                alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                setControl(true)
            });
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSwitch = (event) => {
        if(event.target.name === 'showRestrictiveCovenantsInput') {
            setState(prevState => ({
                ...prevState,
                restrictiveCovenantsText: restrictiveCovenantsTextDefault
            }));

            setModalInputState(prevState => ({
                ...prevState,
                showRestrictiveCovenantsInput: event.target.checked
            }));
        } else if(event.target.name === 'showAdditionalComments') {
            setState(prevState => ({
                ...prevState,
                additionalComments: ''
            }));

            setModalControlState(prevState => ({
                ...prevState,
                showAdditionalComments: event.target.checked
            }))
        } else if(event.target.name === 'showIncentiveCompensation') {
            setState(prevState => ({
                ...prevState,
                showIncentiveCompensation: event.target.checked
            }));
        } else if(event.target.name === 'showCustomReturnDateInput') {
            setState(prevState => ({
                ...prevState,
                custom_return_date: ''
            }));

            setModalInputState(prevState => ({
                ...prevState,
                showCustomReturnDateInput: event.target.checked
            }));
        }
    }

    const handleRequestSelection = e => {
        setRequest(e.row)
        setCurrentRow(e.row.id)
        setUserFeedback('')
        setTemplate(null)
    };

    function handleApprove() {
        var update_params = {...request}
        update_params['approval_status'] = 'approved';
        update_params['approval_date'] = new Date().toISOString();
        update_params['approving_hr_practitioner'] = accounts[0].idTokenClaims.name;
        update_params['verification_status'] = 'unverified';
        update_params['update_action'] = 'approve'

        if(control) {
            fetch((api_url + 'requests/' + update_params.id), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(update_params)}).then((response) => {
                if(response.status === 200) {
                    fetchAllRequests()
                    return 'OK'
                } else {
                    return response.json()
                }
            })
            .then((json) => {
                if(json !== 'OK') {
                    if(json.status === 401 && json.message.includes('Reauthentication Required')) {
                        throw new Error('Reauthentication Required')
                    }
                }
            })
            .catch((error) => {
                setControl(false)
                if(error.message.includes('Reauthentication Required')) {
                    requiredReauthentication('handleApprove()')
                } else {
                    alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                    setControl(true)
                }
            });
            setRequest(undefined)
        }
    };

    const handleTemplate = e => {
        setTemplate(e);
        if(e !== null){
            // reset all the visibility fields to false
            setModalControlState(prevState => ({
                ...prevState,
                showSignOnBonus: false,
                showRelocation: false,
                showRestrictiveCovenants: false,
                showCustomReturnDate:false,
                showAdditionalComments: false
            }));

            setModalInputState(prevState => ({
                ...prevState,
                showIncentiveCompensationInput: false,
                showCustomReturnDateInput: false
            }))

            // set the proper values for the optional fields to show up based on currently selected template
            const changeableRestrictiveCovenants = ['template_b', 'template_c', 'template_d', 'template_e', 'template_f', 'template_g', 'template_h', 'template_j', 'template_k']
            if(e.value === 'template_a') {
                setModalInputState(prevState => ({
                    ...prevState,
                    showIncentiveCompensationInput: true
                }));
            }
            if(e.value === 'template_h') {
                setModalControlState(prevState => ({
                    ...prevState,
                    showSignOnBonus: true
                }));
            }
            if(e.value === 'template_j') {
                setModalControlState(prevState => ({
                    ...prevState,
                    showRelocation: true
                }));
            }
            if(changeableRestrictiveCovenants.includes(e.value)) {
                setModalControlState(prevState => ({
                    ...prevState,
                    showRestrictiveCovenants: true
                }));
            }
            if(e.value !== 'template_i') {
                setModalControlState(prevState => ({
                    ...prevState,
                    showCustomReturnDate: true
                }));
            }
        }
    };

    const handleReject = e => {
        setShowReject(true)
    };

    function finishRejection() {
        var update_params = {...request}
        update_params['approval_status'] = 'rejected';
        update_params['verification_status'] = 'unverified';
        update_params['rejection_reason'] = rejection;
        update_params['update_action'] = 'reject'

        if(control) {
            fetch((api_url + 'requests/' + update_params.id), {headers: {Authorization: token.idToken}, method: 'PUT', body: JSON.stringify(update_params)}).then((response) => {
                if(response.status === 200) {
                    fetchAllRequests()
                    return 'OK'
                } else {
                    return response.json()
                }
            })
            .then((json) => {
                if(json !== 'OK') {
                    if(json.status === 401 && json.message.includes('Reauthentication Required')) {
                        throw new Error('Reauthentication Required')
                    }
                }
            })
            .catch((error) => {
                setControl(false)
                if(error.message.includes('Reauthentication Required')) {
                    requiredReauthentication('finishRejection()')
                } else {
                    alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                    setControl(true)
                }
            });
            setRejection('');
            setShowReject(false);
            setRequest(undefined);
        }
    }

    const generateButton = e => {
        setShowModal(true)
    }

    const resetModalVisibility = e => {
        setShowModal(false)
        setState(prevState => ({
            ...prevState,
            restrictiveCovenantsText: restrictiveCovenantsTextDefault,
            additionalComments: '',
            signOnBonus: '',
            sobDate: '',
            relocation: '',
            custom_return_date: '',
            showIncentiveCompensation: false
        }));

        setModalControlState(prevState => ({
            ...prevState,
            showAdditionalComments: false
        }))

        setModalInputState(prevState => ({
            ...prevState,
            showRestrictiveCovenantsInput: false,
            showCustomReturnDateInput: false,
        }));
    }

    function handleGeneration() {
        if(request !== undefined && template !== null){
            setGenerating(true);
            let body = {'custom_return_date': state.custom_return_date, 'additional_comments': state.additionalComments, 'restrictive_covenants_text': state.restrictiveCovenantsText, 'sign_on_bonus': state.signOnBonus, 'sign_on_bonus_date': state.sobDate, 'show_incentive_compensation': state.showIncentiveCompensation, 'relocation_compensation': state.relocation}

            if(control) {
                fetch((generator_url + 'hrol_' + template.value + '/' + request.id), {headers: {Authorization: token.idToken}, method: 'POST', body: JSON.stringify(body)})
                .then((response) => {
                    setGenerating(false);
                    resetModalVisibility()
                    if(response.status === 200) {
                        setUserFeedback(
                            <Alert severity='success'>
                                <AlertTitle>Success</AlertTitle>
                                <strong>Success!</strong> Your report has been generated.
                            </Alert>)
                        return 'OK'
                    } else {
                        setUserFeedback(
                            <Alert severity='error'>
                                <AlertTitle>Error</AlertTitle>
                                <strong>Error!</strong> There was an issue generating your report.
                            </Alert>
                        )
                        return response.json()
                    }
                }).then((json) => {
                    if(json !== 'OK') {
                        if(json.status === 401 && json.message.includes('Reauthentication Required')) {
                            throw new Error('Reauthentication Required')
                        }
                    }
                    fetchAllRequests();
                    setState({ custom_return_date: '', additionalComments: '', restrictiveCovenantsText: restrictiveCovenantsTextDefault, signOnBonus: '', sobDate: '', relocation: '' })

                    setModalControlState(prevState => ({
                        ...prevState,
                        signOnBonus: false,
                        showRelocation: false,
                        showRestrictiveCovenants: false,
                        showAdditionalComments: false
                    }));

                    setModalInputState(prevState => ({
                        ...prevState,
                        showIncentiveCompensationInput: false,
                        showCustomReturnDateInput: false,
                    }))
                }).catch((error) => {
                    setControl(false)
                    if(error.message.includes('Reauthentication Required')) {
                        requiredReauthentication('handleGeneration()')
                    } else {
                        alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                        setControl(true)
                    }
                    setGenerating(false);
                });
                setTemplate(null);
            }
        }

        if(template === null) { alert('You must select a template before you can generate a offer letter. Please select from the dropdown and try again!')}
    };

    function viewPdf(row) {
        if(control) {
            fetch((generator_url + 'fetch/' + row?.blob_id), {headers: {Authorization: token.idToken}, method: 'GET'}).then((response) => {
                if(response.status === 200) {
                    return response.blob()
                } else if(response.status === 401) {
                    return response.json()
                } else {
                    return null
                }
            })
            .then((blob) => {
                if(blob?.status === 401 && blob?.message.includes('Reauthentication Required')) {
                    throw new Error('Reauthentication Required')
                } else {
                    if(blob !== null && blob !== undefined) {
                        window.open(URL.createObjectURL(blob), '_blank');
                    }
                }
            }).catch((error) => {
                setControl(false)
                if(error.message.includes('Reauthentication Required')) {
                    requiredReauthentication('viewPdf(' + JSON.stringify(row) + ')')
                } else {
                    alert('An unexpected error occurred or the API is unavailable, please try again or contact support if the issue persists.')
                    setControl(true)
                }
            });
        }
    }

    const columns = [
        { field: 'request_date', headerName: 'Request Date', width: 175 },
        { field: 'requestor_name', headerName: 'Requestor', width: 150 },
        { field: 'date_of_hire', headerName: 'Date of Hire', width: 150 },
        { field: 'position', headerName: 'Position', width: 200 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'approval_status', headerName: 'HR Approval', width: 150 },
        { field: 'verification_status', headerName: 'Manager Approval', width: 150 },
        { field: 'envelope_status', headerName: 'Signature Status', width: 150 },
        { field: 'offer_letter', headerName: 'Generated Offer Letter', width: 180,
            renderCell: (cellValues) => {
            return (
                <Button
                    style={{ border: '1px solid black' }}
                    variant="primary"
                    disabled={cellValues.row.blob_id === ''}
                    onClick={() => viewPdf(cellValues.row)}
                >
                    View Letter
                </Button>
            );
        } }
    ];

    return (
        <>
            <Modal
                show={showReject}
                onHide={() => setShowReject(false)}
                size='lg'
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Request Rejection Reasoning
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField placeholder='Enter a rejection reason' fullWidth minRows={4} maxRows={24} variant='filled' multiline id="rejection" name="rejection" value={rejection} onChange={(e) => {const value = e.target.value; setRejection(value)}}></TextField>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowReject(false)}>Cancel</Button>
                    <Button variant="primary" onClick={() => finishRejection()}>Reject</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModal}
                onHide={resetModalVisibility}
                dialogClassName='practitioner-modal'
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id='example-custom-modal-styling-title'>
                        Additional Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={1}>
                                <FormGroup>
                                    <FormControlLabel control={<Switch checked={modalControlState.showAdditionalComments} name='showAdditionalComments' onChange={handleSwitch} />} />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={11}>
                                <h5>Add additional text at the bottom of the offer letter?</h5>
                            </Grid>
                            {modalControlState.showAdditionalComments &&
                                <Grid item xs={12}>
                                    <TextField value={state.additionalComments} onChange={handleChange} name='additionalComments' fullWidth multiline variant='filled' minRows={4} />
                                </Grid>

                            }
                        </Grid>
                        {modalControlState.showRestrictiveCovenants ?
                            <>
                                <br />
                                <Divider />
                                <br />
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={1}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={modalInputState.showRestrictiveCovenantsInput} name='showRestrictiveCovenantsInput' onChange={handleSwitch} />} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <h5>The template you selected has an editable Restrictive Covenants section, would you like to make changes to this section?</h5>
                                    </Grid>
                                    {modalInputState.showRestrictiveCovenantsInput &&
                                        <Grid item xs={12}>
                                            <TextField value={state.restrictiveCovenantsText} onChange={handleChange} name='restrictiveCovenantsText' fullWidth multiline variant='filled' minRows={11} />
                                        </Grid>
                                    }
                                </Grid>
                            </>
                        :
                            <></>
                        }
                        {modalControlState.showSignOnBonus ?
                            <>
                                <br />
                                <Divider />
                                <br />
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12}>
                                        <h5>The offer letter template you selected includes sign on bonus information, please fill out the relevant fields below</h5>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip title='Numeric values only, do not need "$"'>
                                            <span>
                                                <h5 className="field-name">Sign On Bonus</h5>
                                                <input type="text" id="signOnBonus" name="signOnBonus" value={state.signOnBonus} onChange={handleChange} placeholder='Enter dollar amount...' />
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h5 className="field-name">Sign On Bonus Date</h5>
                                        <input type="date" id="sobDate" name="sobDate" value={state.sobDate} onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </>
                        :
                            <></>
                        }
                        {modalControlState.showRelocation ?
                            <>
                                <br />
                                <Divider />
                                <br />
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12}>
                                        <h5>The offer template you selected includes relocation compensation information, please fill out the relevant fields below</h5>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Tooltip title='Numeric values only, do not need "$"'>
                                            <span>
                                                <h5 className="field-name">Relocation Compensation</h5>
                                                <input type="text" id="relocation" name="relocation" value={state.relocation} onChange={handleChange} placeholder='Enter dollar amount...' />
                                            </span>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </>
                        :
                            <></>
                        }
                        {modalInputState.showIncentiveCompensationInput ?
                            <>
                                <br />
                                <Divider />
                                <br />
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={1}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={state.showIncentiveCompensation} name='showIncentiveCompensation' onChange={handleSwitch} />} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <h5>Show IPEC Incentive Compensation?</h5>
                                    </Grid>
                                </Grid>
                            </>
                        :
                            <></>
                        }
                        {modalControlState.showCustomReturnDate ?
                            <>
                                <br />
                                <Divider />
                                <br />
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={1}>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={modalInputState.showCustomReturnDateInput} name='showCustomReturnDateInput' onChange={handleSwitch} />} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <h5 className="field-name">Custom Return Date? If this is not set the default is that the letter will need to be signed by 5pm three days from the date of the generation of this letter.</h5>
                                    </Grid>
                                    {modalInputState.showCustomReturnDateInput &&
                                        <Grid item xs={12}>
                                            <input type="date" id="custom_return_date" name="custom_return_date" value={state.custom_return_date} onChange={handleChange} />
                                        </Grid>
                                    }
                                </Grid>
                            </>
                        :
                            <></>
                        }

                    </Box>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={resetModalVisibility}>Cancel</Button>
                    <Button variant='primary' onClick={handleGeneration}> {generating ? <CircularProgress size={24}/>: 'Generate'} </Button>
                </Modal.Footer>
            </Modal>
            <br /><br /><br />
            <div className="page-container">
                <h2 className="page-title">Request Lookup and Approval:</h2><br />
                <div style={{ height: 400, width: '100%', backgroundColor: 'white', borderRadius: '10px', border: '1px solid black' }}>
                    <DataGrid
                        rows={requests}
                        columns={columns}
                        disableMultipleSelection={true}
                        onCellClick={handleRequestSelection}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'request_date', sort: 'desc' }],
                            },
                        }}
                    />
                </div>
                <br /><br />
                <div className='generate-container'>
                    <div className='dropdown-container'>
                        <Select
                            className='practitioner-dropdown'
                            options={[
                                {value:'template_a', label:'Template A - IPEC Offer Letter Template Salaried Non-Exempt'},
                                {value:'template_b', label:'Template B - ISS Offer Letter Exempt Template - Standard'},
                                {value:'template_c', label:'Template C - ISS Offer Letter - Salary Exempt w- Discretionary Bonus Template'},
                                {value:'template_d', label:'Template D - ISS Offer Letter Salary Exempt w- Discretionary Bonus & Cell Allowance'},
                                {value:'template_e', label:'Template E - ISS Offer Letter Salary Exempt w- Discretionary Bonus-Cell and Auto Allowance'},
                                {value:'template_f', label:'Template F - ISS Offer Letter Salary Exempt w- Commission'},
                                {value:'template_g', label:'Template G - ISS Offer Letter Salary Exempt w- MIP Bonus'},
                                {value:'template_h', label:'Template H - ISS Offer Letter Salary Exempt w- SOB'},
                                {value:'template_i', label:'Template I - ISS Offer Letter NonExempt'},
                                {value:'template_j', label:'Template J - ISS Offer Letter Salary Exempt w- Relocation'},
                                {value:'template_k', label:'Template K - ISS Offer Letter Exempt Template - Standard w- Overtime'}
                            ]}
                            isClearable={true}
                            placeholder="Select Template..."
                            isDisabled={request === undefined || request.envelope_status !== 'Unsent'}
                            onChange={handleTemplate}
                            value={template}
                        />
                    </div>
                    <div className='button-container'>
                        <Button className="generate-button" variant='primary' onClick={generateButton} disabled={request === undefined || template === null || request.envelope_status !== 'Unsent'}>{generating ? <CircularProgress size={24}/>: 'Generate PDF'}</Button>
                    </div>
                </div>
                <br />
                <Button className="practitioner-buttons" variant='success' onClick={() => handleApprove()} disabled={request === undefined || request.blob_id === '' || request.approval_status === 'approved' || request.envelope_status !== 'Unsent'}>{false ? <CircularProgress size={24}/>: 'Approve'}</Button>
                <Button className="practitioner-buttons" variant='danger' onClick={handleReject} disabled={request === undefined || (request.approval_status !== 'unapproved' && request.approval_status !== 'approved') || request.envelope_status !== 'Unsent'}>{false ? <CircularProgress size={24}/>: 'Reject'}</Button>
                <br /><br />
                <div className="user-feedback">{userFeedback}</div>
                <br />
                { request ?
                <>

                    <div className="request-form-preview">
                        { request?.verification_status === "rejected" ?
                            <>
                                <Alert severity='error'>
                                    <AlertTitle>Rejected</AlertTitle>
                                    The offer letter has been REJECTED for the following reason:
                                    {request.rejection_reason}
                                </Alert>
                            </>
                        :
                            <></>
                        }
                        <center><img className='iss-logo' src={iss_logo} alt="Industrial Service Solutions" width={'25%'} /></center>
                        <h2 style={{backgroundColor: 'black', width: '50%', textAlign: 'center', marginLeft: '25%', color: 'white'}}>Offer Letter Request Form</h2><br />
                        <h3 className='request-lookup-values'>{'Name: ' + request['name']}</h3><br />
                        <h3 className='request-lookup-values'>{'Email Address: ' + request['email']}</h3><br />
                        <h3 className='request-lookup-values'>{'Date of Hire: ' + request['date_of_hire']}</h3><br />
                        <h3 className='request-lookup-values'>{'Position: ' + request['position']}</h3><br />
                        <h3 className='request-lookup-values'>{'Work Location: ' + request['work_location']}</h3><br />
                        <h3 className='request-lookup-values'>{'Report To: ' + request['report_to']}</h3><br />
                        <h3 className='request-lookup-values'>{'Annual Salary / Hourly Rate: ' + request['annual_salary'] + ' / ' + request['hourly_rate']}</h3><br />
                        <h3 className='request-lookup-values'>{'Bonus: ' + request['bonus']}</h3><br />
                        <h3 className='request-lookup-values'>{'Vacation: ' + request['vacation']}</h3><br />
                        <h3 className='request-lookup-values'>{'Per Diem: ' + request['per_diem']}</h3><br />
                        <h3 className='request-lookup-values'>{'Auto Allowance: ' + request['auto_allowance']}</h3><br />
                        <h3 className='request-lookup-values'>{'Cell Phone Reimbursement: ' + request['cell_phone_reimbursement']}</h3><br />
                        <h3 className='request-lookup-values'>{'Company Credit Card: ' + request['company_credit_card']}</h3><br />
                        <h3 className='request-lookup-values'>{'Company Credit Card Limit: ' + request['company_credit_card_limit']}</h3><br />
                        <h3 className='request-lookup-values'>{'Business Cards: ' + request['business_cards']}</h3><br />
                        <h3 className='request-lookup-values'>{'Company Equipment: ' + request['company_equipment']}</h3><br />
                        <h3 className='request-lookup-values'>{'Comments: ' + request['comments']}</h3><br />
                    </div>
                </>: <></>}
            </div>
        </>
    );
}

export default PractitionersLookup;