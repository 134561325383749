import './App.css';
import HRNav from './components/HRNav';
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import OfferLetterRequestForm from './components/OfferLetterRequestForm';
import PractitionersLookup from './components/PractitionersLookup';
import ManagerLookup from './components/ManagerLookup';
import Unauthorized from './components/Unauthorized';
import worker from "./images/worker.jpg";
import Button from 'react-bootstrap/Button';
import { AlertContextProvider } from './AlertContext';

function App() {
  const msal = useMsal();

  return (
    <>
    <AlertContextProvider>
      <HRNav />
      <AuthenticatedTemplate>
        <Routes>
          {msal.accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || msal.accounts[0]?.idTokenClaims?.roles.includes("hr_practitioner") || msal.accounts[0]?.idTokenClaims?.roles.includes("hiring_manager")
            ? <Route exact path='/' element={<OfferLetterRequestForm />} /> : <Route exact path='/' element={<Unauthorized />} /> }

          {msal.accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || msal.accounts[0]?.idTokenClaims?.roles.includes("hr_practitioner") || msal.accounts[0]?.idTokenClaims?.roles.includes("hiring_manager")
            ? <Route exact path='/offer-letter-request' element={<OfferLetterRequestForm />} /> : <Route exact path='/offer-letter-request' element={<Unauthorized />} />}

          {msal.accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || msal.accounts[0]?.idTokenClaims?.roles.includes("hr_practitioner") ? <Route exact path='/practitioners-lookup' element={<PractitionersLookup />} /> : <></>}
          {msal.accounts[0]?.idTokenClaims?.roles.includes("hrol_admin") || msal.accounts[0]?.idTokenClaims?.roles.includes("hiring_manager") ? <Route exact path='/manager-lookup' element={<ManagerLookup />} /> : <></>}
        </Routes>
        <br /><br />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
          <div id="gradient-div">
            <br /><br />
            <div className="landing-left">
              <h1 id="welcome-text">Welcome to the Offer Letter Portal!</h1>
              <h2 id="signin-text">Please sign in with your ISS credentials.</h2>
            </div>
            <div className="landing-right">
            <img id="landing-photo" src={worker} alt="landing - worker" />
            </div>
          </div>
          <br /><br />
          <div id="landing-info">
            <Button id="landing-button" variant="outline-dark" href="https://www.iss-na.com">CLICK HERE TO VIEW OUR CORPORATE SITE</Button>
          </div>
      </UnauthenticatedTemplate>
    </AlertContextProvider>
    </>
  );
}

export default App;