import React from "react";
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

interface AlertProps {
    message: string;
    isOpen: boolean;
    onClose: Function;
}

export const Alert: React.FC<AlertProps> = ({ message, isOpen, onClose }) => {
    message = String(message)

    if(!isOpen) {
        return null;
    }

    return (
        <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};